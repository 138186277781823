import {Fragment, useState, useRef, useEffect} from 'react';

import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Toast} from 'primereact/toast';

import {InvoiceService} from '../../service/InvoiceService';

import {HoaDon} from './HoaDon';

const invoiceService = new InvoiceService();

export const TraCuu = () => {

    const [value, setValue] = useState('');
    const [referenceNumber, setReferenceNumber] = useState('');
    const [message, setMessage] = useState(null);
    const [headerInfo, setHeaderInfo] = useState({});
    const [display, setDisplay] = useState(0);

    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [number, setNumber] = useState(null);
    const toast = useRef(null);


    const lockup = () => {
        if (value) {
            invoiceService.getHeaderInfo(value).then((data) => {
                if (data['invoiceTotal'] && data['currencyUomId']) {
                    data['invoiceTotalLocaleString'] = data['invoiceTotal'].toLocaleString('vi-VN', {
                        style: 'currency',
                        currency: data['currencyUomId']
                    });
                }
                setHeaderInfo(data);
                setReferenceNumber(value);
                setMessage(null);
                setDisplay(Date.now);
            }).catch(({response}) => {
                let _message = 'Có lỗi xảy ra...'
                if (response && response.data && response.data.errors) {
                    _message = response.data.errors;
                }
                setMessage(_message);
            });
        }
    }

    const show = (message) => {
        toast.current.show({severity: 'error', summary: 'Lỗi', detail: message});
    };

    const sendInvoice = () => {
        if (number) {
            invoiceService.getToken().then((response) => {
                invoiceService.sendZaloMessage(`84${number}`, response.moquiSessionToken).then((data) => {
                    if (data.success === 'N') {
                        show(data?.errorMessage)
                    } else {
                        toast.current.show({
                            severity: 'success',
                            summary: 'Thành công',
                            detail: `Đã gửi tin nhắn đến số điện thoại ${number}`
                        });
                    }
                })
            })
        } else {
            show('Bạn chưa nhập số điện thoại');
        }
    }

    return (
        <div className="global_container_">
            <Toast ref={toast}/>
            <div className="row">
                <div className="l-constrained group">
                    <div style={{width: '31%'}}>
                        <div className="layer-11" style={{width: "264px", height: "429px"}}></div>
                    </div>
                    <div className="col-3">
                        <div className="col-2">
                            <div className="layer-6" style={{width: "236px", height: "62px"}}/>
                            <div style={{width: '100%'}}>
                                <p className="text">DÙNG THỬ DỊCH VỤ<br/><em className="text-style">(Miễn Phí)</em></p>
                            </div>
                            <div style={{width: '100%'}}>
                                <p className="text-2">Nhập số điện thoại để Gửi/Nhận bản tin hóa đơn</p>
                            </div>
                            <div style={{width: '100%'}}>
                                <p className="text-3">(Lưu ý: Số điện thoại phải đang sử dụng Zalo)</p>
                            </div>
                            <div style={{width: '100%'}}>
                                <div className="layer-7-copy-holder">
                                    <div className="p-inputgroup" style={{height: '2.2rem'}}>
                                    <span className="p-inputgroup-addon" style={{width: '10px', height: '2.2rem'}}>
                                        +84
                                    </span>
                                        <InputText className="" placeholder="VD: 987654321" value={number}
                                                   onChange={(e) => setNumber(e.target.value)}></InputText>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '100%' }}>
                                <div className="row-3 group flex">
                                    <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
                                    <p className="text-5 mt-1">Chấp thuận điều khoản của VINVINOICE</p>
                                </div>
                            </div>
                            <div style={{width: '100%'}}>
                                <div style={{alignItems:'center'}}>
                                    <Button className="layer-8-holder" disabled={!checked}
                                            style={{borderRadius: 'unset', backgroundColor: 'deepskyblue'}}
                                            label="GỬI HÓA ĐƠN" loading={loading} onClick={sendInvoice}/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: '100%', position:'relative'}}>
                            <p className="text-7">CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE - Member of VINCA.</p>
                        </div>
                    </div>
                    <div style={{width: '31%'}}>
                    <div className="col">
                            <p className="text-8">HỖ TRỢ ĐĂNG KÝ</p>
                            <div className="layer-9" style={{width: "68px", height: "59px"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
